import React from 'react'
import {Link} from 'react-router-dom'

import formatMoney from '../../assets/functions/formatMoney.js'

export default function OfferListItem({offer, redeemed, buttonTextColor}) {
  return (
    <li
      key={offer.id}
      className={`p-2 md:p-4 shadow ${
        redeemed ? 'filter grayscale opacity-60' : ''
      }`}>
      <Link to={`/offer/${offer.id}`} className='flex flex-col h-full'>
        <img className='h-40 mx-auto' src={offer.image_url} alt={offer.name} />

        <div className='flex flex-col flex-grow'>
          <div className='flex-grow mt-2'>
            <h3 className='text-xl font-semibold text-dark'>
              Buy {offer.required_groups[0].quantity}, get{' '}
              {formatMoney(offer.discount_cents)} back
            </h3>
            <p className='mt-2 text-sm font-secondary'>{offer.name}</p>
          </div>

          <button
            className={`shrink-0 mt-2 px-2 py-1.5 rounded-full bg-primary text-center ${buttonTextColor} transition duration-300 hover:shadow`}>
            View Offer
          </button>
        </div>
      </Link>
    </li>
  )
}
