import React, {useState, useEffect, useLayoutEffect, useContext} from 'react'

import Context from './context.jsx'

import OfferListItem from './offerListItem.jsx'
import LoadingSpinner from '../shared/utility/loadingSpinner.jsx'
import ajaxRequest from '../../assets/functions/ajaxRequest'
import {set} from 'react-ga'
import SearchIcon from '../shared/icons/searchIcon.jsx'

export default function OffersPage() {
  const {
    brand,
    offers,
    loadingOffers,
    consumerLimitReachedByOffer,
    buttonTextColor,
    authenticityToken,
  } = useContext(Context)

  const [typeFilter, setTypeFilter] = useState('all')
  const [sortBy, setSortBy] = useState('new')
  const [searchTerm, setSearchTerm] = useState('')
  const [filteredOffers, setFilteredOffers] = useState(offers)
  const [productTypes, setProductTypes] = useState([])

  const [email, setEmail] = useState('')
  const [signedUp, setSignedUp] = useState(false)
  const [loading, setLoading] = useState(false)
  const [errorMessage, setErrorMessage] = useState('')

  useEffect(() => {
    setFilteredOffers(sortAndFilterOffers())
  }, [offers, typeFilter, sortBy, searchTerm])

  const sortAndFilterOffers = () => {
    return offers
      .filter((offer) => {
        if (typeFilter === 'all') return true

        var match = offer.required_groups[0].required_products.reduce(
          (match, requiredProduct) => {
            if (
              requiredProduct.product_type.toLowerCase() ===
              typeFilter.toLowerCase()
            )
              match = true
            return match
          },
          false,
        )

        return match
      })
      .filter((offer) => {
        let match = searchTerm === '' ? true : false

        offer.required_groups[0].required_products.forEach(
          (requiredProduct) => {
            if (
              requiredProduct.name
                .toLowerCase()
                .includes(searchTerm.toLowerCase())
            )
              match = true
          },
        )

        return match
      })
      .sort((a, b) => {
        switch (sortBy) {
          case 'new':
            return (
              Math.abs(
                new Date(a.start_date).getTime() - new Date().getTime(),
              ) -
              Math.abs(new Date(b.start_date).getTime() - new Date().getTime())
            )
          case 'endingSoon':
            return new Date(a.end_date) - new Date(b.end_date)
          case 'valueDesc':
            return b.discount_cents - a.discount_cents
          case 'valueAsc':
            return a.discount_cents - b.discount_cents
          default:
            return new Date(b.created_at) - new Date(a.created_at)
        }
      })
  }

  function hasOnlyOneKeyValuePair(obj) {
    return Object.keys(obj).length === 1
  }

  useEffect(() => {
    setProductTypes(
      offers.reduce((productTypes, offer) => {
        const productType =
          offer.required_groups[0].required_products[0].product_type.toLowerCase()
        productTypes[productType] = productType
        return productTypes
      }, {}),
    )
  }, [offers])

  // For Signing up for BrandSite Emails
  function handleSubmit(event) {
    event.preventDefault()
    if (email.length === 0) return
    setLoading(true)
    ajaxRequest('POST', '/email_signup', {
      headers: [{'X-CSRF-Token': authenticityToken}],
      body: {email},
      json: true,
    })
      .then((response) => {
        setSignedUp(true)
        setLoading(false)
      })
      .catch((response) => {
        setLoading(false)
        setErrorMessage('There was an error signing up. Please try again.')
        console.error(response)
      })
  }

  return (
    <>
      <div
        style={{backgroundImage: `url(${brand.banner_background_image})`}}
        className='h-screen-1/4 md:h-screen-1/3 lg:h-screen-1/2 mt-16 bg-cover bg-center'>
        <div className='w-full h-full flex items-center justify-center'>
          <div>
            <h1
              className={`w-full text-3xl md:text-4xl text-center text-white font-bold text-shadow-md ${
                brand.show_search ? `mb-2 md:mb-5 lgplus:mb-10` : ``
              }`}>
              {brand.banner_text}
            </h1>

            {brand.show_search ? (
              <div className='flex justify-between items-center w-full mx-auto px-4 py-3 bg-white rounded-lg shadow'>
                <input
                  className='w-full outline-none'
                  type='text'
                  placeholder='Search offers'
                  onChange={(event) => setSearchTerm(event.target.value)}
                />

                <SearchIcon extraClasses='text-dark' size='6' />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <div className='container mx-auto mt-2 mb-2 md:mb-4 px-4'>
        <div className='flex flex-wrap flex-col sm:flex-row justify-between mb-2 md:mb-4 pb-2 border-b border-light'>
          <div className='space-x-1 sm:space-x-2 space-y-1 sm:space-y-0'>
            {hasOnlyOneKeyValuePair(productTypes) ? (
              <></>
            ) : (
              <>
                {productTypes.beer ? (
                  <button
                    className={`px-2 py-1.5 border rounded-full hover:shadow ${
                      typeFilter === 'beer'
                        ? `bg-primary border-primary ${buttonTextColor}`
                        : 'border-light text-dark'
                    }`}
                    onClick={() =>
                      setTypeFilter(typeFilter === 'beer' ? 'all' : 'beer')
                    }>
                    Beer
                  </button>
                ) : (
                  <></>
                )}

                {productTypes.wine ? (
                  <button
                    className={`px-2 py-1.5 border rounded-full hover:shadow ${
                      typeFilter === 'wine'
                        ? `bg-primary border-primary ${buttonTextColor}`
                        : 'border-light text-dark'
                    }`}
                    onClick={() =>
                      setTypeFilter(typeFilter === 'wine' ? 'all' : 'wine')
                    }>
                    Wine
                  </button>
                ) : (
                  <></>
                )}

                {productTypes.spirit ? (
                  <button
                    className={`px-2 py-1.5 border rounded-full hover:shadow ${
                      typeFilter === 'spirit'
                        ? `bg-primary border-primary ${buttonTextColor}`
                        : 'border-light text-dark'
                    }`}
                    onClick={() =>
                      setTypeFilter(typeFilter === 'spirit' ? 'all' : 'spirit')
                    }>
                    Spirits
                  </button>
                ) : (
                  <></>
                )}
              </>
            )}
          </div>

          <div className='sm:mt-0 space-x-1 sm:space-x-2 space-y-1 sm:space-y-0'>
            <button
              className={`px-2 py-1.5 border rounded-full hover:shadow ${
                sortBy === 'new'
                  ? `bg-primary border-primary ${buttonTextColor}`
                  : 'border-light text-dark'
              }`}
              onClick={() => setSortBy('new')}>
              New
            </button>

            <button
              className={`px-2 py-1.5 border rounded-full hover:shadow ${
                sortBy === 'endingSoon'
                  ? `bg-primary border-primary ${buttonTextColor}`
                  : 'border-light text-dark'
              }`}
              onClick={() => setSortBy('endingSoon')}>
              Ending Soon
            </button>

            <button
              className={`px-2 py-1.5 border rounded-full hover:shadow ${
                sortBy === 'valueDesc'
                  ? `bg-primary border-primary ${buttonTextColor}`
                  : 'border-light text-dark'
              }`}
              onClick={() => setSortBy('valueDesc')}>
              High to Low
            </button>

            <button
              className={`px-2 py-1.5 border rounded-full hover:shadow ${
                sortBy === 'valueAsc'
                  ? `bg-primary border-primary ${buttonTextColor}`
                  : 'border-light text-dark'
              }`}
              onClick={() => setSortBy('valueAsc')}>
              Low to High
            </button>
          </div>
        </div>

        {filteredOffers.length ? (
          <ul className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lgplus:grid-cols-5 justify-center gap-2 md:gap-4'>
            {filteredOffers.map((offer) => (
              <OfferListItem
                key={`offerListItem_${offer.id}`}
                offer={offer}
                redeemed={consumerLimitReachedByOffer[offer.id]}
                buttonTextColor={buttonTextColor}
              />
            ))}
          </ul>
        ) : !loadingOffers ? (
          <div className='mx-auto text-center lg:w-1/2 px-4 pb-4 pt-7'>
            <h1 className='text-base mb-7'>
              No available offers at the moment.
            </h1>
            {brand.about_us ? (
              <div className='mx-auto mb-7'>
                <h1 className='text-xl font-bold'>About Us</h1>
                <div className='text-left w-fit mx-auto'>{brand.about_us}</div>
                {brand.brand_homepage ? (
                  <a
                    className='block w-24 mx-auto'
                    href={brand.brand_homepage}
                    target='_blank'>
                    <span className='border-b border-dark hover:border-gray-500 hover:text-gray-500'>
                      Learn More
                    </span>
                    &nbsp; &gt;
                  </a>
                ) : (
                  <></>
                )}
              </div>
            ) : (
              <></>
            )}
            <EmailSignupForm
              brand={brand}
              email={email}
              setEmail={setEmail}
              handleSubmit={handleSubmit}
              signedUp={signedUp}
              loading={loading}
              errorMessage={errorMessage}
            />
          </div>
        ) : (
          <></>
        )}

        {loadingOffers ? (
          <LoadingSpinner
            size='12'
            color='text-primary'
            extraClasses='mx-auto'
            wrapperClasses='py-3'
          />
        ) : (
          <></>
        )}
      </div>
    </>
  )
}

function EmailSignupForm({
  brand,
  email,
  setEmail,
  handleSubmit,
  signedUp,
  loading,
  errorMessage,
}) {
  if (errorMessage) {
    return (
      <div className='mx-auto mb-8 w-1/2 p-4 border border-light text-center text-red-500'>
        {errorMessage}
      </div>
    )
  }

  return (
    <div className='mx-auto mb-8 border border-light'>
      {loading ? (
        <LoadingSpinner
          size='12'
          color='text-primary'
          extraClasses='mx-auto'
          wrapperClasses='py-3'
        />
      ) : signedUp ? (
        <div className='text-center'>
          {errorMessage
            ? 'Could not sign up, please try again later!'
            : 'Thank You for Signing Up!'}
        </div>
      ) : (
        <form
          onSubmit={handleSubmit}
          className={`w-full mx-auto text-dark px-2 py-7`}>
          <p className='mb-2 text-xl font-bold text-center font-primary lg:whitespace-nowrap'>
            Stay up to date with our latest offers and news
          </p>

          <div className='mx-auto w-52'>
            <label
              className='block text-left text-dark text-sm'
              htmlFor='email'>
              Enter Your Email Address
            </label>
            <input
              className='block w-full p-2 mb-4 border-2 border-light rounded focus:border-primary text-xl outline-none'
              value={email}
              placeholder='johndoe@email.com'
              onChange={(event) => setEmail(event.target.value)}
              type='email'
              id='email'
            />
          </div>

          <button className='w-48 px-2 py-1.5 border rounded-full bg-primary text-white text-center transition duration-300 hover:shadow'>
            Submit
          </button>
          <p className='text-sm text-gray-700 pt-1'>
            We will never share your email with third parties
          </p>
        </form>
      )}
    </div>
  )
}
